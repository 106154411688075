* {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif !important;
  outline: 0;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  background: #000;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
}

.tweet-settings {
  max-width: 340px;
  width: 340px;
  min-width: 340px;
  color: #fff;
  border-right: 1px solid #2f3336;
  height: 100%;
  padding: 25px;
  overflow: auto;
  h3 {
    font-size: 24px;
    font-weight: normal;
    border-bottom: 1px solid #2f3336;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  ul {
    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  label {
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
    color: #6e767d;
  }
  .input {
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #2f3336;
    color: #fff;
    padding: 10px 0 15px;
    font-size: 15px;
    &::placeholder {
      color: #fff;
    }
    &:focus {
      border-color: #4e555a;
    }
  }
  select {
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #2f3336;
    color: #fff;
    padding: 10px 0 15px;
    font-size: 15px;
    option {
      color: #111; // fallback for windows
    }
    &::placeholder {
      color: #fff;
    }
    &:focus {
      border-color: #4e555a;
    }
  }
  button {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    color: #fff;
    background: #1da1f2;
    cursor: pointer;
  }
  .textarea {
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #2f3336;
    color: #fff;
    padding: 10px 0 15px;
    font-size: 15px;
    line-height: 1.5;
    resize: none;
    &::placeholder {
      color: #fff;
    }
    &:focus {
      border-color: #4e555a;
    }
  }
  .download-url {
    display: none;
  }
}

.tweet-container {
  padding: 0 50px;
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .app-language {
    margin-bottom: 15px;
    color: rgba(#fff, 0.5);
    font-size: 14px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    span {
      margin: 0 15px;
      cursor: pointer;
      &.active {
        color: #fff;
      }
    }
  }

  .fetch-info {
    margin-bottom: 0;
    width: 600px;
    display: flex;
    input {
      flex: 1;
      background: #2f3336;
      height: 40px;
      line-height: 40px;
      border-radius: 3px 0 0 3px;
      color: #fff;
      padding: 0 15px;
      font-size: 15px;
    }
    button {
      height: 40px;
      padding: 0 20px;
      font-size: 15px;
      background: #1da1f2;
      color: #fff;
      border-radius: 0 3px 3px 0;
    }
  }
}

.tweet {
  border: 1px solid #2f3336;
  width: 600px;
  margin: 25px auto;
  padding: 0 16px;
  background: #000;
  &-author {
    height: 48px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 15px;
    img {
      width: 48px;
      height: 48px;
      margin-right: 12px;
      border-radius: 50%;
      object-fit: cover;
    }
    .name {
      color: #fff;
      font-weight: 700;
      display: flex;
      align-items: center;
      height: 19px;
      span {
        margin-right: 3px;
      }
    }
    .username {
      color: #6e767d;
    }
  }
  &-content {
    padding: 12px 0;
    p {
      font-size: 23px;
      line-height: 28px;
      color: #fff;
      overflow: hidden;
      span {
        color: #1da1f2;
      }
    }
  }
  &-stats {
    height: 53px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #6e767d;
    border-top: 1px solid #2f3336;
    b {
      color: #fff;
    }
    span:not(:last-child) {
      margin-right: 20px;
    }
  }
  &-actions {
    height: 48px;
    display: flex;
    border-top: 1px solid #2f3336;
    color: #fff;
    align-items: center;
    justify-content: space-around;
  }
}
